import axios from "axios";
import { supabase } from "./supabaseClient";

const initialLoad = async () => {
  try {
    const data = await axios.get("https://ipapi.co/json");
    const ip = data.data.ip ? data.data.ip : "notFound";
    const latitude = data.data.latitude
      ? data.data.latitude.toString()
      : "notFound";
    const longitude = data.data.longitude
      ? data.data.longitude.toString()
      : "notFound";
    const network = data.data.network ? data.data.network : "notFound";
    const org = data.data.org ? data.data.org : "notFound";
    return {
      myuserip: ip,
      myuserlat: latitude,
      myuserlong: longitude,
      myusernet: network,
      myuserorg: org,
    };
  } catch (err) {}
};
//test

const postDetails = async (mydata) => {
  const { data, error, status } = await supabase.rpc("add_data", mydata);
  if (error) return "Error - Vote failed";
  return data;
};

export default { initialLoad, postDetails };
